import React, { useState, useEffect } from 'react'
import './cube.css'
import logo from '../../static/globalsped_logo.jpg'

const LogoCube = ({data}) => {

    const [arrNr, setArrNr ] = useState(0)
    useEffect(() => {
        const i = setInterval(() => {
            
            if(arrNr+2===data.length-1) {
                return setArrNr(0)
            } else {
                return setArrNr(arrNr+3)
            }
        }, 12000)
        return () => {
            clearInterval(i)
        }
    }, [data, arrNr])
    
        
   
    return (
        <section id='stage-wrapper'>
            <div id="stage" >
                <div className="spinner">
                    <ImageFace className='face1' image={logo} />
                    <TextFace className='face2' text={data[arrNr]} />
                    <ImageFace className='face3' image={logo} />
                    <TextFace className='face4' text={data[arrNr+1]} />
                    <ImageFace className='face5' image={logo} />
                    <TextFace className='face6' text={data[arrNr+2]} />
                </div>
            </div> 
        </section>
    )
}

const ImageFace = ({image, className}) =>
    <div className={className}>
        <img src={image} alt='Globalsped Logo' className='logo' width={98} height={134} />

    </div>

const TextFace = ({text, className}) => 
    <div className={className}>
        {text}
    </div>


export default LogoCube
