import React, { Component } from "react";
import Slide from 'react-reveal/Slide'
export class Services extends Component {
  render() {
    return (
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <Slide bottom>
              <h2>{this.props.data && this.props.data.title}</h2>
              <p>
                {this.props.data && this.props.data.text}
              </p>
            </Slide>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.values.map((d, i) => (
                  <Slide key={i} bottom>
                    <div  key={`${d.name}-${i}`} className="col-md-4">
                      {" "}
                      <div className='services-item'>
                        <i className={d.icon}></i>
                        <div className="service-desc">
                          <h3>{d.name}</h3>
                          <p>{d.text}</p>
                        </div>
                      </div>
                    </div>
                  </Slide>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    )
  }
}

export default Services;
