import React from 'react'
import Marquee from './Marquee'
const DestinationMarquee = ({destinations, index, prefix}) => {

    if(!destinations || destinations.legth===0)
      return <div>Klicken für Details</div>
    const staticStyle = {
      display: 'inline-block',
      marginLeft: '8px'
    }
    const dynStyle = prefix ? prefix.styles ? {...staticStyle, ...prefix.styles} : staticStyle :staticStyle

    const dottedCities = destinations.map((destination, i)=>{
      return <span key={destination}>
        {(i===0 && prefix) && <span >
          <i className='fa fa-truck' ></i>
          <span style={dynStyle}>{prefix.text}</span>
          </span>}
        <span className='dot'/>{destination}
      </span>
    })
    return <Marquee content={dottedCities} i={index} />
    }

    export default DestinationMarquee