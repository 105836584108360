import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import TickerBoard from './TickerBoard'

const TickerBoardSensor = ({text}) => {
	const [ tickerState, setTickerState ] = useState(false)
	const onVisibilityChange = (isVisible) =>{
		setTickerState(isVisible)
	}
  
	const tickerSettings = {
		kg: {
			end: 115200,
			duration: 20,
		},
		co2: {
			end: 28338,
			duration: 20,
		},
		years: {
			duration:20,
		}
	}
	const tickerReset = {
		kg: {
			end: 0,
			duration: 0,
		},
		co2: {
			end: 0,
			duration: 0,
		},
		years: {
			end: 0,
			duration: 0,
		}
	}
	return (
		<VisibilitySensor
			scrollCheck={false}
			onChange={onVisibilityChange}
			delayedCall
			partialVisibility
		>
			<div style={{minHeight: '200px'}}>
				<div>
					<TickerBoard
						tickerReset={tickerReset}
						isVisible={tickerState}
						kg={tickerSettings.kg}
						co2={tickerSettings.co2}
						years={tickerSettings.years} 
						text={text}
					/>
				</div>
			</div>
		</VisibilitySensor>
	)


}

export default TickerBoardSensor