import React, { Component } from 'react'
import Slide from 'react-reveal/Slide'
export class about extends Component {

  render() {
  
    return (
        <div id="about">
        <div className="container">
          <div className="row">
          <Slide bottom>
            <div className="col-xs-12 col-md-6">
            {this.props.data.images.map((img, i)=>
              // eslint-disable-next-line
              <img key={i} {...img} className="img-responsive" />
            )}
            </div>
            </Slide>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <Slide bottom>
                  <div>
                    <h2>{this.props.data && this.props.data.title}</h2>
                    {this.props.data ? this.props.data.text.map((para, i)=><p key={i}>{para}</p>) : 'loading...'}
                    <h3>{this.props.data && this.props.data.whyHeadline}</h3>
                  </div>
                </Slide>
                <div className="list-style">
                  <Slide bottom>
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <ul>
                        {this.props.data ? this.props.data.Why.map((d, i) => <li  key={`${d}-${i}`}>{d}</li>) : 'loading'}
                      </ul>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <ul>
                      {this.props.data ? this.props.data.Why2.map((d, i) => <li  key={`${d}-${i}`}> {d}</li>) : 'loading'}

                      </ul>
                    </div>
                  </Slide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default about
