import React, { Component } from "react";
import {withRouter} from 'react-router-dom'
import Slide from 'react-reveal/Slide';
import InputFilter from './InputFilter'
import DestinationMarquee from './Marquee/DestinationMarquee'

export class Destinations extends Component {

  state = {
    updatedData: undefined,
    showDetails: false,
    showModal: false,
    destination: undefined
  }

  handleInputFilter = updatedData => {
    return this.setState({updatedData})
  }

  handleItemClick = (link) => {
    this.props.history.push(link)
    return
  }

  render() {

    const { data } = this.props 
    const { title, text, toolTips, destHoverText } = data
    const { updatedData } = this.state
    const renderData = updatedData ? updatedData : data.values
    return (
      <div id="destinations" className="text-center">
        <div className="container">
          <Slide bottom>
            <div className="section-title">
                <h2>{title}</h2>
                <p>
                  {text}
                </p> 
                <InputFilter
                  title={data.inputFilter.title}
                  initData={this.props.data.values}
                  callback={updatedData=>this.handleInputFilter(updatedData)}
                  filterProps={['title', 'details.cities']}
                />
            </div>
            </Slide>
      
            <div className="portfolio-items">
            {renderData.map((item, index)=> {
              
              const location = {
                pathname: `${this.props.location.pathname==='/' ? '/' : this.props.location.pathname+'/'}${data.prefix.toLowerCase()}-${item.title.toLowerCase()}`,
               
              }
              return (
                <Slide key={`${item.title}`} bottom>
                  <div  className="col-sm-6 col-md-4 col-lg-4">
                    <div
                      title={toolTips.hoverDestination || 'Click here!'}
                      className="portfolio-item"
                      onClick={()=>this.handleItemClick(location)}
                    >
                      <div className="hover-bg">
                        {" "} 
                          <img
                            src={require(`../static/img/destinations/${item.image}-small.jpg`)}
                            className="img-responsive"
                            alt={`${data.prefix} ${item.title}`}
                            width={500}
                            height={333}
                          />{" "}
                          <ImgOverlay
                            title={item.title}
                            marquee={<DestinationMarquee
                                      destinations={item.details.cities}
                                      index={index}
                                      prefix={{text: '', style: 'color: red'}}
                                      />}
                          />
                        {/*</a>*/}{" "}
                      </div>
                      <HoverText index={index} hoverText={destHoverText} data={item.details.runningTime}  />
                    </div>
              
                </div>
              </Slide>
              )
            })}
            </div>           
    
        </div>    
      </div>
    );
  }
}

export default withRouter(Destinations)

const HoverText = ({index, data, hoverText}) => {

  return (
    <div id={`hoverText-${index}`} className="hover-text" >
      <h4>{hoverText.title} <i className='fa fa-truck fa-x2'></i> </h4>
      <div className='hover-content'>{hoverText.subtitle} {data}</div>
      <i className="fa fa-3x fa-info-circle hover-text-info-icon " aria-hidden="true"></i>
        {/*item.text.map((paragraph, index)=><p key={index}>{paragraph}</p>)*/}
    </div>
  )
}

export const ImgOverlay = ({title, marquee}) =>{
  return <div className='portfolio-img-overlay'>
    <h3>
      {title}
    </h3>
      {marquee}
  </div>
}
