import React, { useState, useRef } from 'react'
import { sendMail } from '../firebase'

const authToken = '334-dkeidkgj-9kkj333xslskxeitrDEdd'
const secret = 'GetAccessTo#Mail.2021'

const handleSendMail = async ({name, text, email}) => {
	var data = {
		authToken,
		secret,
		name,
		text,
		email
	}
	
	const response = await sendMail(data)
	return response
}

const formValidation = ({email, name, text, refs, messages}) => {
	let isValide = true
	if(name.length<=5) {
		refs.name.children[0].style.border ='2px solid red'
		isValide = false
		refs.name.children[1].innerHTML = messages.name
	} else {
		refs.name.children[0].style.border = '0px '
		refs.name.children[1].innerHTML = null
	}
	// eslint-disable-next-line
	if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
		isValide = false
		refs.email.children[0].style.border = '2px solid red'
		refs.email.children[1].innerHTML = messages.email
	} else {
		refs.email.children[0].style.border = '0px '
		refs.email.children[1].innerHTML = null
	}
	if(text.length<=10) {
		isValide = false
		refs.text.children[0].style.border = '2px solid red'
		refs.text.children[1].innerHTML = messages.text
	} else {
		refs.text.children[0].style.border = '0px '
		refs.text.children[1].innerHTML = null
	}
	return isValide

}

const ContactForm = ({contactForm}) => {
	const [ showSendMessage, setShowSendMessage ] = useState(undefined)

	const emailRef = useRef(null)
	const nameRef = useRef(null)
	const textRef = useRef(null)
	const toogleEmailSendMessage = () => {
		return setShowSendMessage(undefined)
	}

	const handleSubmit = async e => {
		e.preventDefault()
		const refs={
			email: emailRef.current,
			name: nameRef.current,
			text: textRef.current,
		}
		const text = e.target.elements.message.value
		const name = e.target.elements.name.value
		const email = e.target.elements.email.value
		const inputIsValide = formValidation({email, name, text, refs, messages: contactForm.formValidation})
		if(!inputIsValide)
			return false
			setShowSendMessage({title: undefined, text: undefined, isLoading: true })
		const result = await handleSendMail({name, text, email})
	
		if(!result.data.state) {
			//setIsLoading(false)
			setShowSendMessage({title: contactForm.sendMessages.error.title, text: contactForm.sendMessages.error.title.text, isLoading: false})
		} else {
		
			setShowSendMessage({title: contactForm.sendMessages.success.title, text: contactForm.sendMessages.success.text, isLoading: false})
		//	setIsLoading(false)
		}
	}
	return (
		<React.Fragment>
			{showSendMessage 
				? <EmailSendMessage toogleEmailSendMessage={toogleEmailSendMessage} data={showSendMessage}/>
				: <form onSubmit={handleSubmit} name="sentMessage" id="contactForm" noValidate style={{marginBottom: '8px'}}>
			<div className="row">
				<div className="col-md-6">
					<div ref={nameRef} className="form-group">
						<input
							name='name'
							type="text"
							id="name"
							className="form-control"
							placeholder={contactForm.name}
							required="required"
						/>
						<p ref={nameRef} className="help-block text-danger"></p>
					</div>
				</div>
				<div className="col-md-6">
					<div ref={emailRef} className="form-group">
						<input
							name='email'
							type="email"
							id="email"
							className="form-control"
							placeholder={contactForm.email}
							required="required"
						/>
						<p className="help-block text-danger"></p>
					</div>
				</div>
			</div>
			<div ref={textRef}  className="form-group">
				<textarea
					name="message"
					id="message"
					className="form-control"
					rows="4"
					placeholder={contactForm.message}
					required
				></textarea>
				<p className="help-block text-danger"></p>
			</div>
			<div id="success"></div>
			<button type="submit" className="btn btn-custom btn-lg">
				{contactForm.sendMessageBtn}
			</button>
		</form>}
	</React.Fragment>
	)
}

const EmailSendMessage = ({toogleEmailSendMessage, data}) => {
	const loadingSpinner = <div style={{minHeight: '185px	', border: '1px solid lightgreen', textAlign: 'center', paddingTop: '60px'}}>
		<i className="fa fa-refresh fa-spin fa-4x fa-fw blue" style={{color: 'white'}}></i>
		<span >sending...</span>
	</div>
	const text = <React.Fragment>
		<h2>{data.title}</h2>
		<h3>{data.text}</h3>
	</React.Fragment>

	return <div id='email-send-message'>
	{data.isLoading ? loadingSpinner : text}
		<button onClick={toogleEmailSendMessage} className='btn-custom btn-lg btn'>NEUE Anfrage</button>
	</div>
}
 


export default ContactForm