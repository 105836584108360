const firebaseConfig = {
  PROD: {
    apiKey: "AIzaSyAcAKeGLFX-R49KeDq-RowxlLfo2rsupXU",
    authDomain: "globalsped-web.firebaseapp.com",
    databaseURL: "https://globalsped-web-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "globalsped-web",
    storageBucket: "globalsped-web.appspot.com",
    messagingSenderId: "194892816397",
    appId: "1:194892816397:web:da4a4767444814bd6df544",
    measurementId: "G-PRYM24PJ03"
  },
  TEST: {
    apiKey: "AIzaSyB1vVf6axcO-M_a_TGU26uHyVzs3WUxeos",
    authDomain: "globalsped-ee7ce.firebaseapp.com",
    databaseURL: "https://globalsped-ee7ce-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "globalsped-ee7ce",
    storageBucket: "globalsped-ee7ce.appspot.com",
    messagingSenderId: "774086899879",
    appId: "1:774086899879:web:2a39bfbfc612a398d3c382",
    measurementId: "G-2QTNPRSNPH"

  },
  EMU: {
    apiKey: "AIzaSyAcAKeGLFX-R49KeDq-RowxlLfo2rsupXU",
    authDomain: "globalsped-web.firebaseapp.com",
    projectId: "globalsped-web",
    storageBucket: "globalsped-web.appspot.com",
    messagingSenderId: "194892816397",
    appId: "1:194892816397:web:da4a4767444814bd6df544",
    measurementId: "G-PRYM24PJ03"
  }
}

/*
EMU: {
  apiKey: "AIzaSyB1vVf6axcO-M_a_TGU26uHyVzs3WUxeos",
  authDomain: "globalsped-ee7ce.firebaseapp.com",
  databaseURL: "https://globalsped-ee7ce.firebasedatabase.app",
  projectId: "globalsped-ee7ce",
  storageBucket: "globalsped-ee7ce.appspot.com",
  messagingSenderId: "774086899879",
  appId: "1:774086899879:web:2a39bfbfc612a398d3c382",
  measurementId: "G-2QTNPRSNPH"  
}
*/
export default firebaseConfig