import React, { Component } from "react"
import Slide from 'react-reveal/Slide'

export class features extends Component {
  render() {
    return (
      <div id="features" className="text-center">
      <div className='overlay-features' >
        <div className="container">
          <Slide bottom >
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>{this.props.data.title}</h2>
            {this.props.data.text && <p>{this.props.data.text}</p>}
          </div>
          </Slide>
          <div className="row">
            {this.props.data
              ? this.props.data.values.map((d,i) => {
                return (
                <Slide key={`${d.title}-${i}`} bottom>
                  <div className="col-xs-12 col-md-3">
                    {" "}
                    <div className='features-item'>
                      <i className={d.icon}></i>
                      <h3>{d.title}</h3>
                      {d.text.map((para, i)=><p key={i}>{para}</p>)}
                    </div>
                  </div>
                  </Slide>
                )})
              : "Loading..."}
          </div>
        </div>
        </div>
      </div>
    );
  }
}

export default features;
