import React from 'react'
import values from 'lodash/values'
const LanguageToggler = ({currentLang, languages, changeLangCallback}) => {

   
    const handleChange = e => {
      return changeLangCallback(e.target.value)
    }

  return (
    <div id='language-select'>
        <span className='lang-select-title' >
          {languages.title}
        </span>
        <select
            aria-label=".form-select-sm example"
            className='form-select form-select-sm lang-select'
            onChange={handleChange}
            value={currentLang}
        >
        {values(languages.values).map((lang)=>
          <option className='lang-select-option'key={lang.value} value={lang.value}>
            {lang.title}
          </option>)}
        </select>
 
    </div>
  )
}

export default LanguageToggler
