import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'

export const Footer = ({data, destinations, pathPrefix, language}) => {

  const _destinations = destinations.map(dest=>{
    return {title: `Transport ${dest.title}`, url: `/transport-${dest.title.toLowerCase()}` }
  })
 
  let columnSize = 12
  const columCount = data.columns.length
  if(columCount>=4) {
    columnSize = 3
  } else {
    columnSize = (12/columCount)
  }
  return (
    <section id="footer" >
      <div className="container">
      <h2 className='text-center section-title'>Globalsped</h2> 
      <div className='row'>
       
        {data.columns.map((fcd, i) =>
          <FooterColumn key={i} columnSize={columnSize} title={fcd.title} icon={fcd.icon}>
            {fcd.type==='link-list'
              ? <LinkList language={language} pathPrefix={pathPrefix} data={fcd.subType==='destinations' ? _destinations :fcd.values} />
              : <MemberList data={fcd.values} />
            }
          </FooterColumn>
        )}
      </div>
      <div className='row' title={data.qrCodes.tooltip}>
          <h2 className='text-center section-title'>{data.qrCodes.title}</h2>
          {data.qrCodes.values.map((qrItem, i)=>
            <FooterColumn key={i} title={qrItem.title} columnSize={4} icon={qrItem.icon} >
              <QrCode data={qrItem} />  
            </FooterColumn>
          )}
      </div>
        <Copyright copyright={data.copyright} copyrightLink={data.copyrightLink} />
      </div>
    </section>
    )
}

const FooterColumn = ({title, columnSize, icon, children}) => {

  return (
    <div key={title} className={`col-md-${columnSize}`}>
            <div className='footer-column'>
              <div className='title-wrapper'>
                <i className={`fa fa-${icon} fa-2x`} aria-hidden="true"></i> 
                <h3>{title}</h3> 
              </div>
              <hr className='footer-headline-divider' />
                {children}
            </div>
          </div>
  )
}

const LinkList = ({data, pathPrefix, language}) => {
  const _pathPrefix = language==='de' ? '' : pathPrefix

  return (
    <ul>
    {data.map((colItem,i)=>{
      return <li key={i}><Link to={`${_pathPrefix}${colItem.url}`} >{colItem.title}</Link></li>
    })}
    </ul>
  )
}
const MemberList = ({data}) => {

  return (
    data.map((memberItem, i)=>
      <div key={i} className='member-list-wrapper row' title={memberItem.tooltip}>
          <div id='leadingColumn' className='col-md-6'>
            <h4>{memberItem.title}</h4>
            <h5>{memberItem.subtitle}</h5>
            <a href={memberItem.extURL.url} rel='noopener noreferrer' target='_blank'>
              {memberItem.extURL.text}
            </a>
          </div>
          <div className='col-md-6'>
            <img src={memberItem.imgURL} alt={`logo-${memberItem.title}`} width={150} height={150}/>
          </div>
       
    </div>
    )
  )
}

const QrCode = ({data}) =>{
  return (
    <div className='qr-code-wrapper'>
      <img className='qr-code-image' src={data.imgURL} alt={`Globalsped QR-Code ${data.title}`} width={150} height={150} />
      <h4 className='qr-code-subtitle'>{data.subtitle}</h4>
  </div>)}

const Copyright = ({copyright, copyrightLink})=> {
  return (
    <div id='copyright' >
    <div className='row text-center'>
   
     <p>
        &copy; {copyright}
        <a  href={copyrightLink.url} rel={copyrightLink.rel}>
          {copyrightLink.title}
        </a>
      </p>
     </div>
    </div>
  )
}
export default Footer
