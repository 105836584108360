import React, { Component } from 'react'
import Ticker from './Ticker'
import CountUp from 'react-countup'

const calcDaysInBusiness = () => {
	var dob = new Date('09/01/1997')  
	//calculate month difference from current date in time  
	var month_diff = Date.now() - dob.getTime()  
      
	//convert the calculated difference in date format  
	var age_dt = new Date(month_diff)   
      
	//extract year from date      
	var year = age_dt.getUTCFullYear()  
      
	//now calculate the age of the user  
	var age = Math.abs(year - 1970)  
	
	return age+1
}
const calcMonthDiv = () => {
	const year = 2021
	const month = 8
	const day = 1
	const start_date = new Date(new Date(year, month, day))
	const end_date = new Date(); //Create start date object by passing appropiate argument
	const total_months = (end_date.getFullYear() - start_date.getFullYear())*12 + (end_date.getMonth() - start_date.getMonth())
	return total_months+1
}
const calcAddCounts = (kg, co2) => {
	const monthDiv = calcMonthDiv()
	let kgCounter = kg
	let coCounter = co2
	const kgToAdd = 400
	const coToAdd = 100
	for(let i=0; i<monthDiv;i++){
		kgCounter = kgCounter+kgToAdd
		coCounter = coCounter+coToAdd	
	}
	return {kgCounter, coCounter}
}

class TickerBoard extends Component  {
	state = {
		bussinessYearsEnd: undefined,
		co2End: undefined,
		kgEnd: undefined,
	}

	componentDidMount () {
		const calcBusinessYears = calcDaysInBusiness()
		const { kgCounter, coCounter } = calcAddCounts(this.props.kg.end, this.props.co2.end)
		this.setState({
			bussinessYearsEnd: calcBusinessYears,
			co2End: coCounter,
			kgEnd: kgCounter,
		}) 
	}


	render () {
		const { isVisible, tickerReset, text } = this.props
		return (
			<div className='ticker-board-container'>
				<div className='col-md-4'>
					<Ticker title={text.activeSince.title} suffix={text.activeSince.suffix}>
						<CountUp 
							start={0}
							end={isVisible ? this.state.bussinessYearsEnd : tickerReset.years.end}
							duration={this.props.years.duration}
						/>
						
					</Ticker>
				</div>
						<div className='col-md-4'>
					<Ticker title={text.shipment.title} suffix={text.shipment.suffix} >
						<CountUp 
							start={0}
							end={isVisible ? this.state.kgEnd : tickerReset.kg.end}
							duration={this.props.kg.duration}
							separator='.'
						/>
					</Ticker>
				</div>
				<div className='col-md-4'>
					<Ticker title={text.coSaved.title} suffix={text.coSaved.suffix} >
						<CountUp 
							start={0}
							end={isVisible ? this.state.co2End : tickerReset.co2.end}
							duration={this.props.co2.duration}
							separator='.'
						/>
					</Ticker>
				</div>                       
			</div>
		)
	}

}


export default TickerBoard




