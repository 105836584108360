import { Component } from 'react'

let navbarIsActive = false
let chatNotEl = null



export default class ScrollHandler extends Component {
    componentDidMount () {
    
        let scrollY = window.scrollY
        const navbarEl = document.getElementById('menu')
        const headerBrandEl = document.getElementById('navbar-brand')

        // -------------------------------------------
        window.onscroll = () => {
       
          scrollY= window.scrollY  
          if(this.props.chatNotActive && !chatNotEl) {
            chatNotEl = document.getElementById('chat-notification')
          }
        
          if(scrollY>=30 && !navbarIsActive) {
            navbarIsActive = true  
            if(this.props.chatNotActive) {
              chatNotEl.classList.add('active')
            }
            navbarEl.classList.add('active')
            headerBrandEl.classList.add('active')
            
            } else if (scrollY<=31 && navbarIsActive) {
              navbarIsActive = false
              navbarEl.classList.remove('active')
              headerBrandEl.classList.remove('active')
              if(this.props.chatNotActive)
                chatNotEl.classList.remove('active')  
            } 
        }
    }
    componentWillUnmount () {
      window.onscroll = {}
      navbarIsActive = false
      chatNotEl = null
    }
    render() {
        return null
    }
}
