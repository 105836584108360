import { isArray, includes, filter, get, forEach } from 'lodash'

export const myFilter = ({initData, filterProps, filterInput}) => {
    const isArr = isArray(initData)
    if(isArr) {
        const updatedData = []
        filter(initData, item=>{
            let stringFound = false
            forEach(filterProps, filterProp => {
                const valueToFilter = get(item, filterProp)
                if(isArray(valueToFilter)) {
                    return valueToFilter.map(filterItem=>{
                        stringFound = includes(filterItem.toLowerCase(), filterInput.toLowerCase())
                        if(stringFound && !includes(updatedData, item)) {
                            return updatedData.push(item)
                        }
                        return null
                    })
                }
                stringFound = includes(valueToFilter.toLowerCase(), filterInput.toLowerCase())
                if(stringFound && !includes(updatedData, item))
                    return updatedData.push(item)
            })
            
        })
        return updatedData
    }
    return ['You did not pass an Array']

}
export const checkInput = {
    badString: value => {
        return !/^[0-9a-zA-Z-]+$/.test(value)
    },
    badLastString:  value => {
        const lastString = value.slice(-1)
        const badStrings = ' ?=()§$!"#+*][/<>|'
  
        return includes(badStrings, lastString)
    }
}
