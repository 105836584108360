import React from 'react'
import './flipCard.css'
const FlipCard = ({children}) => {

    return (
        <div className="flip-card">
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    {children[0]}
                </div>
                <div className="flip-card-back">
                    {children[1]}
                </div>
            </div>
        </div>
    )
}

export default FlipCard