import React from 'react'
import {Link} from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Page404 = ({data}) => {

    return (
        <div id='page-not-found'>
        <Helmet >
            <title>{`404-${data.title}`}</title>
            <meta name="description" content={`Globalsped International GmbH -${data.text}`}  />
        </Helmet>
            <div className='page-not-found-overlay'>
                <h1>{data.title}</h1>
                <span className='i-wrapper'>
                    <i className="fa fa-exclamation-triangle fa-5x" aria-hidden="true"></i>
                </span>
                <p style={{textAlign:"center"}}>
                    {data.text}
                </p>
                <Link className="btn btn-custom btn-lg page-scroll" to={data.goToBtn.link}>{data.goToBtn.title} </Link>
            </div>
        </div>
    )
}

export default Page404
