import React from 'react'
import './ticker.css'
const Ticker = ({children, title, suffix}) => {
    return (
        <div className='ticker-wrapper'>
              <h3 style={{display: 'inline-block'}}>
                    {title}
                </h3>
            <div className='ticker' style={{display: 'inline-block'}}>
                <p>
                    {children}
                </p>
            </div>
            <h3 style={{marginTop: '8px'}}>{suffix}</h3>
        </div>
    )
}

export default Ticker
