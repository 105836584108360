import React, { Component, useState, createRef, useEffect} from 'react'
import { Helmet } from 'react-helmet'
import JsonData from './data'
import { Route, useHistory, useLocation, Switch, withRouter, useParams } from 'react-router-dom'
import Navigation from './components/Navigation'
import Header from './components/Header'
import Features from './components/Features'
import About from './components/About'
import Services from './components/Services'
import Destinations from './components/Destinations'
import GreenLogistik from './components/GreenLogistic'
import Team from './components/Team'
import Contact from './components/Contact'
import Footer from './components/Footer'
//import Chat from './components/Chat'
import Page404 from './components/Page404'
import Modal from './components/Modal'
import Slide from 'react-reveal/Slide'
import Fade from 'react-reveal/Fade'
import DestinationDetails from './components/DestinationDetails'
import Imprint from './components/Imprint'
import Jobs from './components/Jobs'
import PrivacyPolicy from './components/PrivacyPolicy'
//import LoadingPage from './LoadingPage'
import ScrollHandler from './components/ScrollHandler'
import VisibilitySensor from 'react-visibility-sensor';
import './static/css'
import LanguageToggler from './components/LanguageToggler'
import NewsBox from './components/NewsBox'
const langLocalSet = localStorage.getItem('language')


const shortBrowserLanguage = langLocalSet 
  ? langLocalSet
  : (navigator.language || navigator.userLanguage).split('-')[0]

export class App extends Component {
  state = {
    data: {...JsonData[shortBrowserLanguage]},
    chatNotActive: false,
    isLoading: true,
    navbar: false,
    currentLanguage: shortBrowserLanguage
  }
 
  componentDidMount () {
    if(this.props.location.pathname==='/' && this.state.currentLanguage!=='de')
      return this.props.history.push('/en')
  }

  handleChatNotCallback = (state) => {
    this.setState({chatNotActive: state})
  }
  handleIsLoading = () => {
    return this.setState({isLoading: false})
  }
  handleNavbarVisibilty = () => {
   return this.setState({navbar: !this.state.navbar})
  }
  handleCangeLanguage = (lang) => {
    localStorage.setItem("language", lang)
    this.setState({
      data: JsonData[lang],
      currentLanguage: lang
    })
    this.props.history.push(lang==='de' ? '/' : `/${lang}`)
  }
  chatBtnRef = createRef()
  
  render() {
    const pathPrefix = this.state.currentLanguage==='de' ? '/' : `/${this.state.currentLanguage}`
    return (
      <div id='App'>
        <Helmet>
          <title>Globalsped International GmbH</title>
          <meta name="description" content={this.state.data.Helmet.discription} />
        </Helmet>
        <LanguageToggler
          languages={this.state.data.languages}
          changeLangCallback={this.handleCangeLanguage}
          currentLang={this.state.currentLanguage}
        />
        <Route path='/news-box' component={NewsBox}/>
      
        {/*<Fade when={this.state.isLoading} collapse top ><LoadingPage interval={1} isLoadingCallback={this.handleIsLoading}/> </Fade>*/}
        <Navigation
 
          data={this.state.data.Navigation.main}
          chatNotActive={this.state.chatNotActive}
          navbar={this.state.navbar}
        />
        <Switch>
          <Route
            path='/404'
            render={({ staticContext }) => {
              if (staticContext) {
                staticContext.statusCode = 404
              }
              return <Page404 data={this.state.data.PageNotFound} />
            }}
          />
          <Route
            path={this.state.data.Navigation.footer[0].pathname}
            component={()=><Fade opposite collapse ><Imprint /></Fade>} 
          />
          <Route
            path={this.state.data.Navigation.footer[1].pathname}
            render={()=>
              <Fade opposite collapse >
                <PrivacyPolicy data={this.state.PrivacyPolicy} />
              </Fade>
            }
          />
          <Route path={pathPrefix} exact>
            <Website
                navItems={this.state.data.Navigation.main}
                data={this.state.data}
                chatNotCallback={this.handleChatNotCallback}
                navbarCallback={this.handleNavbarVisibilty}
                navbar={this.state.navbar}
                chatNotActive={this.state.chatNotActive}
                chatBtnRef={this.chatBtnRef}
            />
          
          </Route>
          <Route
              path={this.state.currentLanguage==='de' ? `/transport-:dest` : `/en/transport-:dest`}
              render={routeProps=>{
                const dest = routeProps.location.pathname.split('-')[1]
                const detailsData = this.state.data.Destinations.values.filter(item=>{
                  const loc = item.title.toLowerCase()

                  if(loc===dest)
                    return item
                  return this.state.data.Destinations[this.state.data.Destinations.length]
                    
                })[0]
                return <Slide when={true} bottom>
                  <DestinationDetails
                    data={detailsData}
                    staticText={this.state.data.Destinations.staticDetails}
                    handleBack={()=>this.props.history.goBack()/*onModalClose*/}
                    handleChat={/*openChat*/console.log('handleChat')}
                  />
                  </Slide>
              }}
            />
        </Switch>  
        {this.props.location.pathname==='/' &&
          <Footer
            pathPrefix={pathPrefix}
            language={this.state.currentLanguage}
            data={this.state.data.Footer}
            destinations={this.state.data.Destinations.values}
          />
        }
      </div>
    )
  }
}
export default withRouter(App)

const Website = ({data, chatNotCallback, navbar, children, chatNotActive, chatBtnRef, navItems}) => {

  const handleActiveNavItem = (isVisible, navItem) => {

    const el = document.getElementById(`nav-${navItem}`)
    if(isVisible) {
      return el.classList.add('active')
    } else {
      return el.classList.remove('active')
    }
  }
  const vsSensorConfig = {
    scrollCheck: false,
    partialVisibility: true,
    offset: {top:300},
    minTopValue: 600,
    intervalCheck: true,
  }
  return (
    <React.Fragment>
      <Header data={data.Header} destinations={data.Destinations.values} chatNotActive={chatNotActive}/>
      <VisibilitySensor onChange={(isVisible)=>handleActiveNavItem(isVisible, 'destinations')} {...vsSensorConfig}>
        <Destinations data={data.Destinations}/>
      </VisibilitySensor>
      <VisibilitySensor onChange={(isVisible)=>handleActiveNavItem(isVisible, 'features')} {...vsSensorConfig}>
        <Features data={data.Features} />
      </VisibilitySensor>
      <VisibilitySensor onChange={(isVisible)=>handleActiveNavItem(isVisible, 'about')} {...vsSensorConfig}>
        <About data={data.About} />
      </VisibilitySensor>
      <VisibilitySensor onChange={(isVisible)=>handleActiveNavItem(isVisible, 'services')} {...vsSensorConfig}>
        <Services data={data.Services} />
      </VisibilitySensor>
      <VisibilitySensor onChange={(isVisible)=>handleActiveNavItem(isVisible, 'green-logistic')} {...vsSensorConfig}>
        <GreenLogistik data={data.GreenLogistik} />
      </VisibilitySensor>
      <VisibilitySensor onChange={(isVisible)=>handleActiveNavItem(isVisible, 'team')} {...vsSensorConfig}>
        <Team data={data.Team} />
      </VisibilitySensor>
      <VisibilitySensor onChange={(isVisible)=>handleActiveNavItem(isVisible, 'jobs')} {...vsSensorConfig}>
        <Jobs data={data.Jobs} />
      </VisibilitySensor>
      <VisibilitySensor onChange={(isVisible)=>handleActiveNavItem(isVisible, 'contact')} {...vsSensorConfig}>
        <Contact data={data.Contact} />
      </VisibilitySensor>
      {/*<Chat chatBtnRef={chatBtnRef} data={data.Chat} chatNotCallback={chatNotCallback} chatNotificationActive={chatNotActive} headerInViewport={chatNotActive ? !navbar : false}/>*/}
      {children}
      <ScrollHandler chatNotActive={chatNotActive} />
    </React.Fragment>
  )
}

const DestinationDetailsModal = ({data, staticText, chatBtnRef}) => {
 let { dest } = useParams()

  const history = useHistory()
  
  const location = useLocation()
  const { pathname } = location
  
  if(dest.match('/'))
      dest = dest.substring(0, dest.length-1)
  
  // Get the destination text from the destination title
  // eslint-disable-next-line
  dest = pathname.split('-')[1]
  const detailsData = data.filter(item=>{
    const loc = item.title.toLowerCase()

    if(loc===dest)
      return item
    return data[data.length]
      
  })[0]
  //const { state: {destId} } = location
  
  const bodyEl = document.getElementById('page-top')
 useEffect(() => {

   return () => {
    bodyEl.style.removeProperty('overflow')
   }
 }, [data, bodyEl])
 

  const [animation, setAnmiation ] = useState(undefined)
  const onModalClose = e => {
    if(e)
    e.stopPropagation()
    setAnmiation(false)
   
    setTimeout(() => {
    history.goBack()
    }, 500);
  }

  const openChat = () => {
    onModalClose()
    return chatBtnRef.current.click()
  }

  return (
    
    <Modal onClose={onModalClose} >
      <Slide when={animation} bottom>
      <DestinationDetails
        data={detailsData}
        staticText={staticText}
        handleBack={onModalClose}
        handleChat={openChat}
      />
      </Slide>
    </Modal>)
}

