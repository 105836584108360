import React, {Fragment, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import './destinationDetails.css'
import logo from '../static/globalsped_logo.jpg'
import { HashLink as Link } from 'react-router-hash-link'
//import { useHistory } from 'react-router-dom'
const DestinationDetails = ({data, staticText, handleBack, handleContact, handleChat, language}) =>{
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])
	
	return (
		<Fragment>
			<Helmet>
				<title>{`${staticText.helmet.title} ${data.title}`}</title>
				<meta name="description" content={`${staticText.helmet.discription} ${data.details.cities.join(' ')} ${staticText.helmet.discriptionSufix} ${data.title}`}  />
				<meta name="theme-color" content="#00a633" />
			</Helmet>
			<DestDetailsWrapper image={data.image} >
				<Header title={data.title} emblem={data.emblem} onClose={handleBack} />
				<Content language={language} data={data} staticText={staticText} handleBack={handleBack} handleContact={handleContact} handleChat={handleChat} />
			</DestDetailsWrapper>
		</Fragment>
	)
}

export default DestinationDetails

const DestDetailsWrapper = ({image, children}) =>{
	const bgImage = require(`../static/img/destinations/${image}-large.jpg`)
	return <div id='dest-details' style={{backgroundImage: `url(${bgImage})`, paddingTop: '0rem'}}>
		<div className='dest-details-overlay' >
			<div className='container text-center dest-details-body'>
				{children}
			</div>
		</div>
	</div>}

const Header = ({title, emblem, onClose}) =>
	<div className='row'>
		<div id='dest-details-header'>
			<div>
				<img className='spinning-logo' src={emblem.url} alt={emblem.alt} />
				<i title='Close' onClick={onClose}style={{color: 'white', float: 'right', opacity: 0.6, cursor: 'pointer'}} className="fa fa-angle-down fa-4x" aria-hidden="true"></i>
  
			</div>
			<h1 style={{color: 'white'}}>
				{title}
			</h1>
		</div>
	</div>

const ColumnWrapper = ({children}) =>
	children && children.map((child, i)=>
		<div key={i} className='col-md-4 col-sm-6' >
			{child}
		</div>
	)
const AddInfoBox = ({data, title}) =>
	<div className='col-lg-12 col-sm-6 col-md-12'>
		<div className='dest-details-content-box' style={{minHeight: '60px'}} >
			<h2 style={{color: 'yellowgreen'}}>{title}</h2>
			<div>{data.map((p, i)=><p key={i}>{p}</p>)}</div>
		</div>
	</div>
  

const FlagImage = ({flag, title}) => {
	return <div className='flag-image-wrapper'>
		<img 
			style={flag.url==='logo' ? {width: '100px', height: 'auto'} : {}}           
			src={flag.url==='logo' ? logo : flag.url}
			alt={`${flag.alt}-${title}`}
		/>
	</div>
}

const Content = ({data, staticText, handleBack, handleContact, handleChat, language}) => {
	const { documentations, informationsRequired } = staticText
	return <div className='row' style={{textAlign: 'left'}}>
		<ColumnWrapper>
			<ContentListBox staticText={staticText} data={data} />
			<ContentBox
				title={documentations.title}
				text={documentations.text}
				values={documentations.values}
			/>
			<ContentBox
				title={informationsRequired.title}
				text={informationsRequired.text}
				values={informationsRequired.values}
			>
				<FlagImage flag={data.flag} title={data.title}/>
				<hr style={{width: '100%'}}/>
				<CompanyLine />
			</ContentBox>

           
		</ColumnWrapper>
		{data.details.additionalInfos && <AddInfoBox data={data.details.additionalInfos} title={staticText.additionalInfos.title} />}
		<ButtonBox buttons={staticText.buttons} handleBack={handleBack} handleContact={handleContact} handleChat={handleChat}/>
	</div>
}
const CompanyLine = () => {
	return (
		<div style={{verticalAlign: 'middle', marginTop: '8px'}}>
			<img style={{margin: 0, marginRight: '8px'}} src={logo} width='30px' alt='Globalsped Logo' /> <h2 style={{color: 'white', fontSize: '', fontFamily: 'Arimo, sans-serif', display: 'inline-block', verticalAlign: 'middle', margin: 0}}>Globalsped</h2>
		</div>
	)
}

const ContentBox = ({title, text, values, children}) =>
	<div className='dest-details-content-box' >
		<h2>{title}</h2>
		<h3>{text}</h3>
		<ul>
			{values.map(doc=>{
				return <li key={doc}>{doc}</li>
			})}
		</ul>
		{children && children}
	</div>

const ContentListBox = ({staticText, data}) =>
	<div className='dest-details-content-box'>
		<h2>{staticText.hardFacts.title}</h2>
		<ContentBoxItem title={staticText.cities.title} text={staticText.cities.text} value={data.details.cities.join(', ')} />
		<ContentBoxItem title={staticText.customsTerminal.title} text={staticText.customsTerminal.text} value={data.details.customsTerminal} />
		<ContentBoxItem title={staticText.route.title} text={staticText.route.text} value={data.details.route.toString()} />
		<ContentBoxItem title={staticText.runningTime.title} text={staticText.runningTime.text} value={data.details.runningTime.toString()} />
		<ContentBoxItem title={staticText.lastEuBorder.title} text={staticText.lastEuBorder.text} value={data.details.lastEuBorder.toString()} />
	</div>

const ContentBoxItem = ({title, text, value}) =>
	<div className='content-box-item'>
		<h4 >{title}</h4>
		<div>{text}</div>
		<div>{value}</div>
	</div>

const ButtonBox = ({buttons, handleBack, handleContact, handleChat, language}) => {
	// const history = useHistory()
	//const contactLink = language==='de' ? '/#contact' : `/${language}/#contact`

	return (
		<div className='col-lg-12 col-sm-6 col-md-12'>
			<div className='dest-details-content-box' style={{minHeight: '60px'}} >
				<h2>Ihre Optionen</h2>
				{buttons.map((button, i)=>{
					if(button.type==='back') 
						return <button key={i} className='btn btn-danger btn-large' onClick={handleBack}>{button.title}</button>
					if(button.type==='contact')
						return <Link key={i} to='/#contact'><button key={i} className='btn btn-success btn-large'>{button.title}</button></Link>
					if(button.type==='disabled-chat')
						return <button key={i} className='btn btn-info btn-large' onClick={handleChat}>{button.title}</button>
					return null
				})}
          
			</div>
		</div>
	)
}
  
