import React, { useState} from 'react'
import "./jobs.css"
import Slide from 'react-reveal/Slide'

const JobsPage = (props) => {
    const  [ jobToggle, setJobToggle] = useState({
        title: props.data.corporatePresentation.title,
        index: 0
    })

    const mouseoverCallback = (type, index) => {
        const isEnter = type==='enter'
        if(isEnter && jobToggle.title===props.data.corporatePresentation.title) {
            return setJobToggle({title: 'Stellenbeschreibung', index})
        } else if (!isEnter && jobToggle.title!==props.data.corporatePresentation) {
            return setJobToggle({title: props.data.corporatePresentation.title, index})
        }
    }

    return (
        <div id='jobs' className="text-center">
            <div id='jobs-container' className='container'>
                <Slide bottom>
                    <div className="col-md-8 col-md-offset-2 section-title">
                        <h2>{props.data &&  props.data.title}</h2>
                        <p className='jobs-subtitle'>
                        {props.data && props.data.subtitle}
                        </p>
                    </div>
                </Slide>
                <Slide bottom >
                    <div className='col-md-6 foo'>
                        <div className='jobs-container'>
                            <Jobs.Title title={props.data.text.title} />
                        </div>
                        <Jobs.Content >
                            <Jobs.Text text={props.data.text.introduction} />
                            <Jobs.Buttons
                                mouseoverCallback={mouseoverCallback}
                                jobs={props.data.jobOffers.offers}
                                title={props.data.jobOffers.title}
                            />
                            <Jobs.Text text={props.data.text.nonSpecialist} />
                        </Jobs.Content>
                    </div>
                </Slide>
                <Slide bottom>
                    <div className='col-md-6 bar'>
                        <div className='company-intro-container'>
                            <Jobs.Title title={jobToggle.title} />
                        </div>
                            <div className='jobs-text-wrapper' >
                                {
                                    jobToggle.title===props.data.corporatePresentation.title
                                        ?  <CompanyIntro
                                                text={props.data.corporatePresentation.text}
                                                email={props.data.corporatePresentation.email}
                                                footer={props.data.corporatePresentation.footer}
                                            />
                                    :  <JobDescription
                                            jobDescription={props.data.jobDescriptions[jobToggle.index]}
                                            jobTitle={props.data.jobOffers.offers[jobToggle.index]}
                                            jobToggle={jobToggle}
                                            footer={props.data.corporatePresentation.footer}
                                            email={props.data.corporatePresentation.email}
                                        />
                                }
                            </div>        
                    </div>
                </Slide>
            </div>
        </div>
  )

}

const CompanyIntro = ({text, email, footer}) => 
<>
<Jobs.Text text={text} />
<Jobs.CompIntro.Footer
    email={email}
    text={footer}
/>
</>

const JobDescription = ({jobTitle, jobDescription, footer, email}) =>
<>
<h3 className='jobs-btn-title'>{jobTitle}</h3>
<ul className='jobs-description-list'>
    {jobDescription.map((p, i)=>{
        return (
            <li key={i} >{p}</li>
        )
    })}
</ul>
<Jobs.CompIntro.Footer text={footer} email={email} />

</>

const JobButtons = ({jobs, title, mouseoverCallback}) => {
    const btnStyle = {
        marginTop: '1rem'
    }
    const handleMouseOver = (index) => {
        return  mouseoverCallback('enter', index)
    }
    const handleMouseLeave = index => {
        return mouseoverCallback('leave', index)
    }
    return (
        <div className='jobs-btn-wrapper'>
            <h4 className='jobs-btn-title'>{title}</h4>
            <ul>{jobs.map((job, i)=>
                <li key={`${i}-${job}`}
                    onMouseEnter={()=>handleMouseOver(i)}
                    onMouseLeave={()=>handleMouseLeave(i)}
                    style={i>0 ? btnStyle : {}}
                    role='button' data-toggle="button"
                    className='jobs-btn btn-success'
                >
                    {job}
                </li>)}
            </ul>
        </div>
    )
}


const Text = ({text}) =>text.map((tx, i)=><p key={i}>{tx}</p>)
  

const JobsTitle = ({title}) =>
    <div className='jobs-title-wrapper'>
        <h3>{title}</h3>
    </div>

const JobsContentWrapper = ({children}) =>
    <div className='jobs-content-wrapper'>
        {children}
    </div>

const CompanyIntroFooter = ({text, email}) => {
    const _email = `mailto:${email}`
    return (
        <div className='company-footer-wrapper'>
            <h3>{text}</h3>
            <a href={_email} >{email}</a>
        </div>
    )
}
const Jobs = {
    Title: JobsTitle,
    Text,
    Content: JobsContentWrapper,
    CompIntro: {
        Footer: CompanyIntroFooter,
    },
    Buttons: JobButtons
}
export default JobsPage
