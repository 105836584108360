import React from "react";
import Slide from 'react-reveal/Slide'
//import AntCube from './AntCube'
import TickerBoard from './TickerBoard/TickerBoardSensor'

const GreenLogistic = ({data}) =>
  <div id="green-logistic">
    <div className="container">
      <h2 className='green-logistic-h2'>{data && data.title}</h2>
      <Slide bottom>
        <div id="green-logistic-header" className='text-center green-logistic-item'>
          {data.text && data.text.map((pa, i)=><p key={i}>{pa}</p>)}
        </div>
      </Slide>
      <Slide bottom>
        <div className='row' style={{marginBottom: '16px'}}>          
          <TickerBoard text={data.tickerBoard} />
        </div>
      </Slide>
      <div className="row">
      <Slide bottom>
      {data.values.map(greenLogItem=>{
        return (
          <div key={greenLogItem.title} className='col-md-6' >
            <div className='green-logistic-item'>
              <h3>{greenLogItem.title}</h3>
              <p>{greenLogItem.text}</p>
            </div>
          </div>
        )
      })}
      </Slide>
      </div>
    </div>
  </div>

export default GreenLogistic;
