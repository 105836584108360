import React from "react";
import Cube from './LogoCube'
import DestinationMarquee from './Marquee/DestinationMarquee'
//import UkraineOverlay from "./Header/UkraineOverlay"


const convertDestinations = (destinations) => {
  const destTitles = destinations.map(dest=>{
    return dest.title
  })
  return destTitles
}

const Header = ({destinations, data}) =>
  <header id="header">
    <div className="intro">
      <div className="overlay">
        <div className="container">
          <div className="row">
            <div className="intro-text">
              <Cube data={data.logoCube}/>
              <h1>
                {data ? data.title : "Loading...."}
              </h1>
              <div id='Marquee-Wrapper'>
                <DestinationMarquee
                  destinations={convertDestinations(destinations)}
                  index='header'
                  prefix={{text: data.marquee.prefix, style: 'color: red'}}
                />
              </div>
              {/*<UkraineOverlay />*/}
              <a
                href={data && data.button.destinations.href}
                className="btn btn-custom btn-lg page-scroll"
              >
                {data ? data.button.destinations.title : 'Loading...'}
                {" "}
              </a>{" "}
            </div>
            <TextGridBox text={data.text} />
          </div>
        </div>
      </div>
    </div>
  </header>


export default Header;

const TextGridBox = ({text}) => {
 
  return (
    <section className='grid-container col-md-12'>
      {text.map((textItem, i)=>{
        return (
          <div key={i} className='col-md-4'>
            <div className='grid-item'>
                <h2>{textItem.title}</h2>
                <div className='text-wrapper'>
                  {textItem.text}
                </div>
            </div>
          </div>
        )
      })} 
    </section>
  )
}