import React from 'react'
import './marquee.css'
const Marquee = ({content, i}) => {
	/*
	if (document.getElementById(`marquee-${i}`)) {
		const laufschrift = document.getElementById(`marquee-${i}`);
		const len = laufschrift.innerHTML.length;
		
		const nodes = document.getElementById(`marquee-${i}`).getElementsByTagName("span")
		for(let i=0; i<nodes.length; i++) { // weil jeder whitespace als child zählt
        
		    nodes[i].style.animationDuration = len/10 +"s"
		}
	}
*/
    return (
        <div id={`marquee-${i}`} className="marquee" style={{ overflow: '-webkit-marquee', padding: '0 20px 0 20px', boxSizing: 'border-box'}}>
            <div >
                {content || null}
            </div>
        </div>
    )
}

export default Marquee
