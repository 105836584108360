import React from 'react';
// becouse of react-snap -  import ReactDOM from 'react-dom';
import { hydrate, render } from "react-dom";
import './index.css';
import App from './App';

//import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom'
import { createBrowserHistory } from "history";
//import { analytics } from './firebase/index.js'

const history = createBrowserHistory()
/*
function sendToAnalytics({ id, name, value }) {
  analytics('send', 'event', {
    eventCategory: 'Web Vitals',
    eventAction: name,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate
  });
}

*/
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {

  hydrate(<Router history={history}>
    <App />
  </Router>, rootElement);
} else {
  render(<Router history={history}>
    <App />
  </Router>, rootElement);
}
/*
ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById('root')
);
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//console.log for example
//reportWebVitals(sendToAnalytics);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();


