import firebase from "firebase/compat/app"
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/storage'
import 'firebase/compat/analytics'
import 'firebase/compat/functions'
//import 'firebase/performance'
import config from './config'


// Initialize Firebase
firebase.initializeApp(config.PROD)
//console.log(firebase.app().options_.projectId)
//firebase.performance()
const auth = firebase.auth()
//auth.languageCode = 'de'
const database = firebase.database()
const storage = firebase.storage()
const analytics = firebase.analytics()

const functions = firebase.functions()
/*
database.useEmulator("localhost", 9000)
auth.useEmulator("http://localhost:9099")
functions.useEmulator('localhost', 5001)
storage.useEmulator("localhost", 9199)
*/
const sendMail = functions.httpsCallable('sendMail')

export {
    database,
    analytics,
    auth,
    storage,
    sendMail,
  }