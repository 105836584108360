import React, { PureComponent, Fragment } from "react"
import logo from '../static/globalsped_logo.jpg'
import Roll from 'react-reveal/Roll'
import ContactForm from './ContactForm'

const defaultMapsRef = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3265.569731658648!2d12.97404131582756!3d47.86705357794226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477684ff29d9e687%3A0x65514402856e65a0!2sPendelhagen%201%2C%2083416%20Saaldorf-Surheim!5e1!3m2!1sde!2sde!4v1614442410782!5m2!1sde!2sde"

export class Contact extends PureComponent {
  state = {
    showContactFrame: false,
    mapsRef: defaultMapsRef,
    activeIframe: undefined
  }

  toogleContactFrame = (mapsRef, currentIframe) => {
    if(this.state.activeIframe || mapsRef==='close') {
      if(currentIframe===this.state.activeIframe || mapsRef==='close') {
        return this.setState({
          showContactFrame: !this.state.showContactFrame,
          activeIframe: undefined,
          mapsRef: defaultMapsRef
        })
      } else {
        return this.setState({
          activeIframe: currentIframe,
          mapsRef,
        })
      }
    }
    return this.setState({
      activeIframe: currentIframe,
      mapsRef,
      showContactFrame: !this.state.showContactFrame
    })
  }
   
  render() {
    const { title, text, contactForm, mapsCloseBtn} = this.props.data
  
    return (
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <img className='logo' src={logo} alt='Logo Globalsped GmbH' width={248} height={339} />
                <h2>{title}</h2>
                <p>
                  {text}
                </p>
              </div>
              <Roll top collapse when={this.state.showContactFrame}>
                <GoogleMapsFrame toogleContactFrame={this.toogleContactFrame} mapsLocationRef={this.state.mapsRef}  closeBtn={mapsCloseBtn} />
              </Roll>
              <Roll bottom opposite collapse when={!this.state.showContactFrame}>
                <ContactForm contactForm={contactForm} />
              </Roll>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            {this.props.data && this.props.data.values.map((contact, index)=>{
              return (
                <Fragment key={index}>
                  <div className="contact-item">
                    <h3>{contact.title}</h3>
                    <span className='contact-headline'></span>
                    <p className='address' onClick={()=>this.toogleContactFrame(contact.address.maps.ref, contact.title)}>
                      <span>
                        <i className="fa fa-map-marker"></i>
                        {contact.address.title}
                      </span>
                      {contact.address.value}
                    </p>
                  </div>
                  <div className="contact-item">
                  <a style={{color: 'rgba(255,255,255,.75)'}} href={`tel: ${contact.phone.value}`} >
                    <p>
                      <span>
                        <i className="fa fa-phone"></i>
                        {contact.phone.title || "loading"}
                      </span>{" "}
                      {contact.phone.value || "loading"}
                    </p>
                  </a>
                  </div>
                  <div className="contact-item">
                  <a style={{color: 'rgba(255,255,255,.75)'}} href={`mailto: ${contact.email}`} rel="nofollow">
                    <p>
                      <span>
                        <i className="fa fa-envelope-o"></i>
                        {contact.email.title}
                      </span>{" "}
                        {contact.email.value || "loading"}
                    </p>
                    </a>
                  </div>
            </Fragment>
            )})}
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a
                      rel="noopener noreferrer" 
                      href={this.props.data ? this.props.data.facebook : "/"}
                      target='_blank'
                    >
                      {" "}
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer" 
                      href={this.props.data ? this.props.data.twitter : "/"}
                      target='_blank'
                    >
                      {" "}
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer" 
                      href={this.props.data ? this.props.data.youtube : "/"}
                      target='_blank'
                    >
                      {" "}
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}



  const GoogleMapsFrame = ({toogleContactFrame, mapsLocationRef, closeBtn}) => {

    return (
      <div className='iframe-wrapper'>
        <div style={{width: '100%', textAlign: 'right'}} role='button' className='btn' onClick={()=>toogleContactFrame('close')}>{closeBtn.title}</div>
        <iframe 
          id='google-iframe'
          className='google-iframe'
          title='Headquater Pendelhagen'
          src={`${mapsLocationRef} width='100%' height='400' style={{border:0}} allowFullScreen='' loading='lazy'`}
          width='100%' height='390'
          style={{border: 'none'}}
        >
        </iframe>
    
      </div>
    )
  }


export default Contact;
