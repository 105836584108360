import React, { Component } from "react"
import FlipCard from './FlipCard'
import Slide from 'react-reveal/Slide'
import noImage from '../static/no-team-pic.gif'

export class Team extends Component {
  render() {
    return (
      <div id="team" className="text-center">
          <div className="container">
            <Slide bottom>
              <div className="col-md-8 col-md-offset-2 section-title">
                <h2>{this.props.data && this.props.data.title}</h2>
                <p>
                  {this.props.data && this.props.data.text}
                </p>
              </div>
            </Slide>
          <div id="row">
            {this.props.data
              ? this.props.data.values.map((d, i) => (
                  <Slide key={i} bottom>
                    <div  key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                      <div className="thumbnail">
                        {" "}
                        <FlipCard >
                          {d.imgURL 
                            ? <img  src={d.imgURL} alt={d.name} className="team-img" width={240} height={240} />
                            : <img src={noImage} alt='No Pic available' className="team-img" width={200} height={200} style={{height: '200px', width: '200px'}} />
                          }
                          <ContactCard data={d} headline={this.props.data.contactCardsHeadline} toolTips={this.props.data.toolTips} />
                        </FlipCard>
                        <div className="caption">
                          <h4>{d.name}</h4>
                          <p>{d.job}</p>
                        </div>
                      </div>
                    </div>
                  </Slide>
                ))
              : "loading"}
          </div>
       
        </div>
      </div>
    );
  }
}

const ContactCard = ({data : {contact}, headline, toolTips}) => {
  const emailSplit = contact.email.split('@')
  const emailName = emailSplit[0]
  const emailDomain = emailSplit[1]
  const emailPic = <React.Fragment>{emailName}<i className='fa fa-at' aria-hidden='true'></i>{emailDomain}</React.Fragment>
  return (
    <div className='team-contact-container'>
      <h3 className='team-contact-headline'>{headline}</h3>
        <ul className='team-contact-body'>
          <li>
            <a title={toolTips.phone} rel='nofollow' href={`tel: ${contact.phone}`}>
              <i className="fa fa-phone fa-2x"></i>
              <p className='team-contact-phone'>{contact.phone}</p>
            </a>
          </li>
          <hr className='team-contact-divider' />
          <li>
            <a title={toolTips.email} rel='nofollow' href={`mailto: ${contact.email}`}>
              <i className="fa fa-envelope-o fa-2x"></i>
              <p className='team-contact-email'>{emailPic}</p>
            </a>
          </li>
      </ul>
    </div>
  )
}

export default Team;
