import React from "react";
import { useLocation } from 'react-router-dom'
import { NavHashLink as Link } from 'react-router-hash-link'
//import LanguageToggler from "./LanguageToggler";

const Navigation = ({data, children, isVisible})=> {
  const location = useLocation()
  const notMainNav = location.pathname==='/impressum' || location.pathname==='/datenschutzerklaerung' || location.pathname==='/404'
  const handleNavBtnClick = () => {
      const elClassList = document.getElementById('bs-example-navbar-collapse-1').classList
      if(elClassList.contains('collapse')) {
        return elClassList.remove('collapse')
      }
      return elClassList.add('collapse')
  }
    
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div id='navbar-header' className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              onClick={handleNavBtnClick}
            >
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <Link id='navbar-brand' className="navbar-brand page-scroll" to="/#page-top">
              Globalsped
            </Link>
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
             {data.map((navItem, i)=>{
                return <li key={i}>
                  <Link
                    onClick={handleNavBtnClick}
                    id={`nav-${navItem.hash.substring(1)}`}
                    activeClassName='active'
                    to={notMainNav ? `/${navItem.hash}` : `${navItem.hash}`} className='page-scroll menu-nav-links'>
                    {navItem.title}
                  </Link>
                </li>}
             )}
            </ul>
          </div>
          
        </div>
      </nav>
    )
}

export default Navigation;
