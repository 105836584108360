import React, { useState, useEffect } from 'react'
import { myFilter } from '../js/utilities'

// Property needs
// initList = array of Objects
// callback = function with filtered array - callback
// counter = length of filteredList parent Component - filter not activ needs null
// filterProps = Object props of the initList for filtering
// filterActive true or false

const InputFilter = ({ initData, callback, filterProps, style, title }) => {
    const [itemCount, setItemCount ] = useState(initData ? initData.length : 0)
    const [ inputValue, setInputValue ] = useState('')
    const [ placeholderTitle, setPlaceholderTitle ] = useState(title)
    

    useEffect(() => {   
        setItemCount(initData.length)
    }, [initData.length])
    
    const resetInput = inputValue => { 
        if(inputValue.length>0) {
            setInputValue('')
            setPlaceholderTitle(title)
            setItemCount(initData.length)
            return callback(undefined)
        }
        setPlaceholderTitle(title)
        return callback(undefined)
   
    }
    const handleFilterInput = inputValue => {
        const filteredArr = myFilter({initData, filterInput: inputValue, filterProps})
        setInputValue(inputValue)
        setItemCount(filteredArr.length)
        callback(filteredArr)
    }
  
    return (
        
        <form>
            <input
                
                value={inputValue}
                autoComplete='off'
                color='primary'
                id='filterInput'
                name='filterInput'
                style={style}
                placeholder={placeholderTitle}
                onBlur={e=>resetInput(e.target.value)}
                onClick={()=>setPlaceholderTitle('')}
                className='input-filter'
                /*inputProps={{
                    'aria-label': 'Description',
                }} */
                //autoFocus
                onChange={e=>handleFilterInput(e.target.value)}
             
            />
            <p className='input-filter-text' style={{margin: 0, color: itemCount===0 ? 'red' : 'inherit'}}>{itemCount} {inputValue.length===0 ? 'Destination total' : 'Destination found'} </p>    
        </form>
    )
}

export default InputFilter