import React from 'react'

 const NewsBox = () =>{
  return (
    <div style={{
      borderRadius: '8px',
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
      color: 'white',
      border: '2px solid white',
      height: '180px', width: '220px', marginTop: '25vh', zIndex: '10000000000', backgroundColor: '', fontSize: '20px', position: 'fixed', right: 20}}>
        <div style={{backgroundColor: 'white', color: 'black', textIndent: '4px', textTransform: 'uppercase'}}>
          Abfahrten
        </div>
          <div style={{padding: '8px'}} >
          <div style={{fontSize: '1.5rem', color: 'darkgray'}}>Batu 22.11.2023</div>
          <div style={{fontSize: '1.5rem'}}>Kiev 22.11.2023</div>
          </div>
        </div>
  )
}

export default NewsBox
